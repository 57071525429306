.sort {
  cursor: pointer;

  .material-icons-asc {
    transform: rotate(180deg);
  }
}

.gift-card-header-extra-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .extra-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
  }
}

@media (max-width: 790px) {
  .gift-card-header-extra-buttons {
    flex-direction: column;
    align-items: flex-start; /* Align items to the left for better mobile UI */

    .extra-buttons {
      width: 100%; /* Take full width in column layout */
      justify-content: flex-start; /* Center buttons in mobile view */
      margin: 10px 0; /* Add some spacing */
    }
  }
}
